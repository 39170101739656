import React, { useState } from "react";
import { MdClose, MdFlag, MdVolumeOff } from "react-icons/md";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import config from "../../config";
import { useScadaPlusTheme } from "../../hooks/useScadaPlusTheme";
import { resolveAlarmPriorityTileColor } from "../../utils/alarm-utils";
import AlarmPriorityDisplayTile from "../AlarmPriorityDisplay";
import AlarmCommentSection from "./AlarmCommentSection";
import AlarmInfoHeader from "./AlarmInfoHeader";
import AlarmAcknowledged from "./AlarmAcknowledged";
import { getSectionId } from "../../utils/routing-utils";
import {
  getAlarmPrioritiesQueryName,
  getAlarmsQueryName,
} from "../../utils/query-invalidation-utils";
import EditCorrectiveActionButton from "./CorrectiveAction/EditCorrectiveActionButton";
import TextInputTitle from "./TextInputTitle";
import CorrectiveActionsText from "./CorrectiveAction/CorrectiveActionsText";
import { useIntl } from "react-intl";
import Button from "components/Button";
import { StandardButton } from "components/Button/Button";
import Modal from "components/Modal";
import { PortalConsumer } from "components/Modal/Modal";
import TextAreaWithButtons from "components/TextAreaWithButtons";
import { useGetAlarm } from "hooks/useGetAlarms";
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  justify-content: center;
  padding: ${(props) => props.theme.spacing.heavy};
  border: 3px solid ${(props) => props.theme.colors.mainColor.tertiary};
`;

const Spacer = styled.div`
  & > * {
    margin-bottom: 8px;
  }
`;

const TopRow = styled.div`
  display: grid;
  grid-template-columns: 2fr auto;
  height: 2.5em;
`;

const Priority = styled.div<PriorityProps>`
  display: flex;
  grid-column: 1/2;
  gap: 10px;
  align-self: flex-end;
  color: ${(props) => {
    return props.priorityLevel >= 3
      ? props.theme.colors.textColor.primary
      : resolveAlarmPriorityTileColor(props.priorityLevel, props.theme);
  }};
`;

const GridButton = styled.div`
  grid-column: 2;
  grid-row: 1;
  justify-self: end;
`;

const Acknowledged = styled.div`
  width: 100%;
`;

const Title = styled.div<TitleProps>`
  grid-column: 1/6;
  grid-row: ${(props) => (props.newLine ? "1/3" : "1/2")};
  display: flex;
  font-size: ${(props) => props.theme.scaledFontSizes.large};
  word-break: break-all;
`;

const ActionBar = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 2em;
`;

const ActionButtons = styled.div`
  grid-column: 6/-1;
  display: flex;
  gap: 5px;
  justify-self: end;
`;

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  word-break: break-word;
`;

const DescriptionTitle = styled.div`
  color: ${(props) => props.theme.colors.textColor.quaternary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  font-family: ${(props) => props.theme.primaryFontFamily};
  font-weight: normal;
`;

const DescriptionText = styled.div`
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
  font-family: ${(props) => props.theme.primaryFontFamily};
`;

const TitleActionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: end;
  color: ${(props) => props.theme.colors.textColor.quaternary};
  padding-top: ${(props) => props.theme.spacing.heavy};
`;

const CorrectiveActionsTitle = styled.div`
  grid-column: 1/3;
  color: ${(props) => props.theme.colors.textColor.quaternary};
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
  font-family: ${(props) => props.theme.primaryFontFamily};
  font-weight: normal;
  padding-top: ${(props) => props.theme.spacing.heavy};
`;

const CommentContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  overflow-y: scroll;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  align-items: start;
  max-height: 15vh;
  color: ${(props) => props.theme.colors.mainColor.primary};
`;

const CommentsTitle = styled.div`
  font-size: ${(props) => props.theme.scaledFontSizes.medium};
`;

const AddCommentButton = styled.div`
  grid-column: 3/4;
  justify-self: end;
  & div {
    color: ${(props) =>
      props.theme.colors.buttonColors.primaryButtonTextColor.primary};
  }
`;

const NoComment = styled.div`
  grid-row: 2/3;
  color: ${(props) => props.theme.colors.textColor.primary};
  font-size: ${(props) => props.theme.scaledFontSizes.small};
`;

type FlagProps = {
  $flagged: boolean; //Transient props ($)
};

type TitleProps = {
  newLine: boolean;
};

const Flag = styled(MdFlag)<FlagProps>`
  color: ${(props) =>
    props.$flagged
      ? props.theme.colors.buttonColors.primaryButtonTextColor.primary
      : props.theme.colors.iconColor};
`;

type VolumeProps = {
  $isVolumeOn: boolean; //Transient props ($)
};

const VolumeIcon = styled(MdVolumeOff)<VolumeProps>`
  color: ${(props) => {
    return props.$isVolumeOn
      ? props.theme.colors.buttonColors.primaryButtonTextColor.primary
      : props.theme.colors.iconColor;
  }};
`;

type Input = {
  comment?: string;
};

type PriorityProps = {
  priorityLevel: number;
};

type Params = {
  plantId: string;
  unitId: string | undefined;
  alarmId: string;
};

export const AlarmInfo: React.FC = () => {
  const { theme } = useScadaPlusTheme();
  const history = useHistory();
  const { plantId, unitId, alarmId } = useParams<Params>();
  const sectionId = getSectionId(history.location.search);
  const queryClient = useQueryClient();
  const alarmPrioritiesQueryName = getAlarmPrioritiesQueryName([
    plantId,
    sectionId,
    unitId,
  ]);
  const alarmsQueryName = getAlarmsQueryName([plantId, sectionId, unitId]);
  const { formatMessage } = useIntl();
  const [comment, setComment] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [volume, setVolume] = useState<boolean>(false);

  const { data } = useGetAlarm(alarmId);

  const mutateAcknowledge = useMutation((id: string) =>
    axios
      .put(`${config.api.url}/${config.api.genericAlarm}/${id}/acknowledge`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => true)
      .catch(() => false),
  );

  const mutationFlagAlarm = useMutation<any, any, Input>((flagAlarm) =>
    axios
      .put(
        `${config.api.url}/${config.api.genericAlarm}/${data!.id}/flag`,
        JSON.stringify(flagAlarm),
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(() => true)
      .catch(() => false),
  );

  const mutationUnFlagAlarm = useMutation<any, any, void>(() =>
    axios
      .put(`${config.api.url}/${config.api.genericAlarm}/${data!.id}/unflag`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => true)
      .catch(() => false),
  );

  const mutationCorrectiveAction = useMutation<any, any, Input>((input) =>
    axios
      .put(
        `${config.api.url}/${config.api.genericAlarm}/${data!.id}/correctiveAction`,
        JSON.stringify(input),
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(() => true)
      .catch(() => false),
  );

  const mutationAddComment = useMutation<any, any, Input>((flagComment) =>
    axios
      .put(
        `${config.api.url}/${config.api.genericAlarm}/${data!.id}/comment`,
        JSON.stringify(flagComment),
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(() => true)
      .catch(() => false),
  );

  const invalidateQueries = (alarmId: string) => {
    queryClient.invalidateQueries(alarmId);
    queryClient.invalidateQueries(alarmPrioritiesQueryName);
    queryClient.invalidateQueries(alarmsQueryName);
    queryClient.invalidateQueries(`activeAlarmsForUnit-${unitId}`);
  };

  const acknowlegeAlarm = async () => {
    const res = await mutateAcknowledge.mutateAsync(alarmId);
    if (res) {
      invalidateQueries(alarmId!);
    }
  };

  const flagAlarm = async () => {
    const res = await mutationFlagAlarm.mutateAsync({ comment: "" });
    if (res) {
      invalidateQueries(alarmId!);
    }
  };

  const unFlagAlarm = async () => {
    const res = await mutationUnFlagAlarm.mutateAsync();
    if (res) {
      invalidateQueries(alarmId!);
    }
  };

  const addCorrectiveAction = async (input: Input) => {
    setEdit(false);
    const res = await mutationCorrectiveAction.mutateAsync(input);
    if (res) {
      invalidateQueries(alarmId!);
    }
  };

  const addComment = async (input: Input) => {
    setComment(false);
    const res = await mutationAddComment.mutateAsync(input);
    if (res) {
      invalidateQueries(alarmId!);
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  const requiresNewLine = (text?: string): boolean => {
    if (!text) return false;
    return text.length > 50 ? true : false;
  };

  return (
    <Modal>
      <Container>
        {data?.id && (
          <Spacer>
            <TopRow>
              <Priority priorityLevel={data.priority}>
                <div style={{ alignSelf: "flex-end" }}>
                  {formatMessage({
                    id: "alarms.Priority",
                    defaultMessage: "Priority",
                  })}
                </div>
                <AlarmPriorityDisplayTile alarmPriortity={data.priority}>
                  {data.priority}
                </AlarmPriorityDisplayTile>
              </Priority>

              <PortalConsumer>
                {(value) => (
                  <GridButton>
                    <Button
                      style={{
                        borderRadius: "50%",
                        height: "35.19px",
                      }}
                      onclick={() => value.close!()}
                      size="xs"
                      type="primary"
                      icon={<MdClose fill={theme.colors.iconColor} size={18} />}
                    />
                  </GridButton>
                )}
              </PortalConsumer>
            </TopRow>
            <Acknowledged>
              {data.isAcknowledged && (
                <AlarmAcknowledged
                  acknowledgedAt={data.acknowledgedAt}
                  acknowledgedBy={data.acknowledgedBy}
                />
              )}
            </Acknowledged>
            <ActionBar>
              <Title newLine={requiresNewLine(data.description)}>
                {data.description}
              </Title>
              <ActionButtons>
                <StandardButton
                  color={
                    volume
                      ? theme.colors.signal
                      : theme.colors.buttonColors.primaryButtonColor
                  }
                  size="small"
                  icon={<VolumeIcon $isVolumeOn={volume} />}
                  onclick={() => setVolume(!volume)}
                />
                <StandardButton
                  size="small"
                  color={
                    data.isFlagged
                      ? theme.colors.signal
                      : theme.colors.buttonColors.primaryButtonColor
                  }
                  icon={<Flag $flagged={data.isFlagged} />}
                  onclick={() => (data.isFlagged ? unFlagAlarm() : flagAlarm())}
                />
                {data.isAcknowledged ? null : (
                  <Button type="primary" size="small" onclick={acknowlegeAlarm}>
                    <span>
                      {formatMessage({
                        id: "alarms.Acknowledge",
                        defaultMessage: "Acknowledge",
                      })}
                    </span>
                  </Button>
                )}
              </ActionButtons>
            </ActionBar>

            <AlarmInfoHeader
              code={data.code}
              timestamp={data.timestamp}
              componentUnitName={data.componentUnitName}
              area={data.area}
              supplier={data.supplier}
            />
            <BodyContent>
              <Description>
                <DescriptionTitle>
                  {formatMessage({
                    id: "alarms.Description",
                    defaultMessage: "Description",
                  })}
                </DescriptionTitle>
                <DescriptionText>Lorem</DescriptionText>
              </Description>

              <TitleActionGrid>
                <CorrectiveActionsTitle>
                  {formatMessage({
                    id: "alarms.CorrectiveAction",
                    defaultMessage: "Corrective Action",
                  })}
                </CorrectiveActionsTitle>
                <EditCorrectiveActionButton
                  edit={edit}
                  onClick={() => setEdit(!edit)}
                  correctiveAction={data.correctiveAction}
                />
              </TitleActionGrid>
              <CorrectiveActionsText
                edit={edit}
                onSubmit={async (text) =>
                  await addCorrectiveAction({ comment: text })
                }
                onCancel={() => setEdit(!edit)}
                correctiveAction={data.correctiveAction}
              />
              <TitleActionGrid>
                <CommentsTitle>
                  {formatMessage({
                    id: "alarms.FlagComment",
                    defaultMessage: "Comments",
                  })}
                </CommentsTitle>
                {data.comments.length === 0 && (
                  <NoComment>
                    <div>
                      {formatMessage({
                        id: "alarms.NoComment",
                        defaultMessage: "No comment",
                      })}
                    </div>
                  </NoComment>
                )}

                {!comment && (
                  <AddCommentButton>
                    <StandardButton
                      color={theme.colors.buttonColors.primaryButtonColor}
                      size="small"
                      onclick={() => {
                        setComment(!comment);
                      }}
                    >
                      {formatMessage({
                        id: "alarms.AddComment",
                        defaultMessage: "Add comment",
                      })}
                    </StandardButton>
                  </AddCommentButton>
                )}
              </TitleActionGrid>
            </BodyContent>
            {comment && (
              <div>
                <TextInputTitle>
                  {formatMessage({
                    id: "alarms.AddComment",
                    defaultMessage: "Add comment",
                  })}
                </TextInputTitle>
                <TextAreaWithButtons
                  onSubmit={async (text) => {
                    await addComment({
                      comment: text,
                    });
                  }}
                  onCancel={() => {
                    setComment(!comment);
                  }}
                />
              </div>
            )}
            {data.comments.length > 0 && (
              <CommentContainer>
                {data!.comments.map((comment) => {
                  return (
                    <AlarmCommentSection
                      key={comment.id}
                      comment={comment.comment}
                      createdAt={comment.createdAt}
                      createdBy={comment.createdBy}
                    />
                  );
                })}
              </CommentContainer>
            )}
          </Spacer>
        )}

        <PortalConsumer>
          {(value) => {
            value.onClose = handleClose;
            return <span></span>; //TODO:Had to be added due to react complaints. Use Hook - context?
          }}
        </PortalConsumer>
      </Container>
    </Modal>
  );
};
