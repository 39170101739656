import HourlyProductionChart from "components/HourlyProductionChart";
import InfoCardErrorView from "components/InfoCardErrorView";
import config from "config";
import { useScadaPlusQuery } from "hooks/useScadaPlusQuery";
import { useScadaPlusTheme } from "hooks/useScadaPlusTheme";
import React, { useState } from "react";
import { Production, ProductionProps } from "types";
import { constructProductionUrl } from "utils/production-utils";

// used for demo dummy data
import {
  hourlyGraphDemoDummyData,
  isDemoUrl,
} from "utils/demo-dummy-data-utils";

export const HourlyProductionCard: React.FC<ProductionProps> = (props) => {
  const { theme } = useScadaPlusTheme();
  const [lastQueriedTimestamp, setLastQueriedTimeStamp] = useState<
    Date | undefined
  >(undefined);

  const [clientError, setClientError] = useState<string | undefined>(undefined);

  const { data, isFetching, error } = useScadaPlusQuery<Production>(
    `hourProduction-${props.idCollection.plantId}-${props.idCollection.sectionId}`,
    () => {
      const aggregateUrlParam = "aggregateBy=Hour";
      const baseUrl = `${config.api.url}/${config.api.production}`;
      return constructProductionUrl(
        baseUrl,
        props.idCollection,
        aggregateUrlParam,
      );
    },
    15 * 1000,
    (errorMessage) => {
      if (errorMessage.status) {
        setClientError(
          `Server response status: ${errorMessage.status} - ${errorMessage.text}`,
        );
      } else {
        setClientError(errorMessage.text);
      }
    },
    () => {
      setLastQueriedTimeStamp(new Date());
    },
  );

  return error || clientError ? (
    <InfoCardErrorView
      lastQueriedTimestamp={lastQueriedTimestamp}
      theme={theme}
      // TODO, hvordan typedefinere error i UseQueryResult slik at komponent vet returnert feil-type
      error={typeof error === "string" ? error : ""}
    />
  ) : (
    // toDo: remove demodummydata part when we can provide simulated data
    // this is only a temporary fix to provide dummy data to demo graphs
    <div>
      <HourlyProductionChart
        data={isDemoUrl() ? hourlyGraphDemoDummyData(props.idCollection) : data}
        isFetching={isFetching}
      />
    </div>
  );
};
