import config from "config";
import { IdCollection, Production } from "types";
import HourlyDemoDummyData from "HourlyProductionDummyData.json";
import DailyDemoDummyData from "DailyProductionDummyData.json";
import MonthlyDemoDummyData from "MonthlyProductionDummyData.json";

// PlantID's for demoplants
export enum PlantIdEnum {
  DoggerbankA = "c769a8d7-eb6c-4612-a214-79ee790e87d9",
  HywindScotland = "526e0529-f3e0-4031-90b4-f97bd00f7397",
  ListaVindpark = "4215da38-65f3-4acc-884f-ba99870d68fc",
}

// sectionID's for demo sections
export enum SectionIdEnum {
  DoggerbankAS1 = "a3f11b6a-cd16-49ae-b6d9-cbffce3ba49d",
  DoggerbankAS2 = "59c5dcd3-1a9f-419a-9771-e4979b35e654",
  DoggerbankAS3 = "1f0573ed-036d-480b-8562-d69eb57073a9",
  HywindScotlandS1 = "372ae0d4-0b5b-4e66-9322-a590d5da9d49",
  HywindScotlandS2 = "65f982a6-1ba8-486a-9c50-7b029dad2a86",
  ListaVindParkS1 = "1dc75473-476a-4178-b2b6-f89d5290498b",
  ListaVindParkS2 = "4905fb87-d15b-4b50-a608-318bb9128303",
  ListaVindParkS3 = "6ecfbf49-cd9a-4f3d-8b8b-edd983620ea4",
  ListaVindParkS4 = "4a95c419-cf76-4924-8b7f-b268bf836d51",
}

// check if it's demo, when working localy use clientid for demo from config.js
export const isDemoUrl = () => {
  return config.redirectUri
    ? config.redirectUri?.match("demo")
      ? true
      : false
    : false;
};

// sets the "key value" (the value in the top right corner) to the last value of the list
export const getLastValidDummyData = (dummyData: Production | undefined) => {
  if (!dummyData || !dummyData.data) {
    return undefined;
  }

  const filteredData = dummyData.data
    .slice()
    .reverse()
    .find((item) => item.actual > 0);

  return filteredData?.actual;
};

// sets dummy data for the Hour card on Demo environment
export const hourlyGraphDemoDummyData = (IdCollection: IdCollection) => {
  if (IdCollection.sectionId != undefined) {
    return sectionDummyDataHourlyGraph(IdCollection.sectionId);
  } else if (IdCollection.plantId) {
    return plantDummyDataHourlyGraph(IdCollection.plantId);
  } else {
    return HourlyDemoDummyData.doggerbank;
  }
};

export const plantDummyDataHourlyGraph = (plantId: string) => {
  switch (plantId) {
    case PlantIdEnum.DoggerbankA:
      return HourlyDemoDummyData.doggerbank;
    case PlantIdEnum.HywindScotland:
      return HourlyDemoDummyData.hywindScotland;
    case PlantIdEnum.ListaVindpark:
      return HourlyDemoDummyData.listaVindpark;

    default:
      return HourlyDemoDummyData.doggerbank;
  }
};

export const sectionDummyDataHourlyGraph = (sectionId: string) => {
  switch (sectionId) {
    case SectionIdEnum.DoggerbankAS1:
      return HourlyDemoDummyData.doggerbankS1;
    case SectionIdEnum.DoggerbankAS2:
      return HourlyDemoDummyData.doggerbankS2;
    case SectionIdEnum.DoggerbankAS3:
      return HourlyDemoDummyData.doggerbankS3;
    case SectionIdEnum.HywindScotlandS1:
      return HourlyDemoDummyData.hywindScotlandS1;
    case SectionIdEnum.HywindScotlandS2:
      return HourlyDemoDummyData.hywindScotlandS2;
    case SectionIdEnum.ListaVindParkS1:
      return HourlyDemoDummyData.listaVindparkS1;
    case SectionIdEnum.ListaVindParkS2:
      return HourlyDemoDummyData.listaVindparkS2;
    case SectionIdEnum.ListaVindParkS3:
      return HourlyDemoDummyData.listaVindparkS3;
    case SectionIdEnum.ListaVindParkS4:
      return HourlyDemoDummyData.listaVindparkS4;

    default:
      return HourlyDemoDummyData.doggerbank;
  }
};

// sets dummy data for the Day(24h) card on Demo environment
export const dailyGraphDemoDummyData = (IdCollection: IdCollection) => {
  if (IdCollection.sectionId != undefined) {
    return sectionDummyDataDailyGraph(IdCollection.sectionId);
  } else if (IdCollection.plantId) {
    return plantDummyDataDailyGraph(IdCollection.plantId);
  } else {
    return DailyDemoDummyData.doggerbank;
  }
};

export const plantDummyDataDailyGraph = (plantId: string) => {
  switch (plantId) {
    case PlantIdEnum.DoggerbankA:
      return DailyDemoDummyData.doggerbank;
    case PlantIdEnum.HywindScotland:
      return DailyDemoDummyData.hywindScotland;
    case PlantIdEnum.ListaVindpark:
      return DailyDemoDummyData.listaVindpark;

    default:
      return DailyDemoDummyData.doggerbank;
  }
};

export const sectionDummyDataDailyGraph = (sectionId: string) => {
  switch (sectionId) {
    case SectionIdEnum.DoggerbankAS1:
      return DailyDemoDummyData.doggerbankS1;
    case SectionIdEnum.DoggerbankAS2:
      return DailyDemoDummyData.doggerbankS2;
    case SectionIdEnum.DoggerbankAS3:
      return DailyDemoDummyData.doggerbankS3;
    case SectionIdEnum.HywindScotlandS1:
      return DailyDemoDummyData.hywindScotlandS1;
    case SectionIdEnum.HywindScotlandS2:
      return DailyDemoDummyData.hywindScotlandS2;
    case SectionIdEnum.ListaVindParkS1:
      return DailyDemoDummyData.listaVindparkS1;
    case SectionIdEnum.ListaVindParkS2:
      return DailyDemoDummyData.listaVindparkS2;
    case SectionIdEnum.ListaVindParkS3:
      return DailyDemoDummyData.listaVindparkS3;
    case SectionIdEnum.ListaVindParkS4:
      return DailyDemoDummyData.listaVindparkS4;

    default:
      return DailyDemoDummyData.doggerbank;
  }
};

// sets dummy data for the Day(24h) card on Demo environment
export const monthlyGraphDemoDummyData = (IdCollection: IdCollection) => {
  if (IdCollection.sectionId != undefined) {
    return sectionDummyDataMonthlyGraph(IdCollection.sectionId);
  } else if (IdCollection.plantId) {
    return plantDummyDataMonthlyGraph(IdCollection.plantId);
  } else {
    return MonthlyDemoDummyData.doggerbank;
  }
};

export const plantDummyDataMonthlyGraph = (plantId: string) => {
  switch (plantId) {
    case PlantIdEnum.DoggerbankA:
      return MonthlyDemoDummyData.doggerbank;
    case PlantIdEnum.HywindScotland:
      return MonthlyDemoDummyData.hywindScotland;
    case PlantIdEnum.ListaVindpark:
      return MonthlyDemoDummyData.listaVindpark;

    default:
      return MonthlyDemoDummyData.doggerbank;
  }
};

export const sectionDummyDataMonthlyGraph = (sectionId: string) => {
  switch (sectionId) {
    case SectionIdEnum.DoggerbankAS1:
      return MonthlyDemoDummyData.doggerbankS1;
    case SectionIdEnum.DoggerbankAS2:
      return MonthlyDemoDummyData.doggerbankS2;
    case SectionIdEnum.DoggerbankAS3:
      return MonthlyDemoDummyData.doggerbankS3;
    case SectionIdEnum.HywindScotlandS1:
      return MonthlyDemoDummyData.hywindScotlandS1;
    case SectionIdEnum.HywindScotlandS2:
      return MonthlyDemoDummyData.hywindScotlandS2;
    case SectionIdEnum.ListaVindParkS1:
      return MonthlyDemoDummyData.listaVindparkS1;
    case SectionIdEnum.ListaVindParkS2:
      return MonthlyDemoDummyData.listaVindparkS2;
    case SectionIdEnum.ListaVindParkS3:
      return MonthlyDemoDummyData.listaVindparkS3;
    case SectionIdEnum.ListaVindParkS4:
      return MonthlyDemoDummyData.listaVindparkS4;

    default:
      return MonthlyDemoDummyData.doggerbank;
  }
};
